<template>
  <div class='d-flex flex-column'>
    <div class='d-flex flex-row justify-space-around align-center'>
      <v-btn
        color='accent'
        @click='generateData()'
      >
        Generate data
      </v-btn>
      <!-- <v-select
        class="select ml-2"
        :items='visualModes'
        label='Visual modes'
        v-model='selectedVisualMode'
      /> -->
      <v-switch
        v-model='showData'
        label='Show data'
      ></v-switch>
      <v-switch
        v-model='outlinedChip'
        label='Outlined chip'
      ></v-switch>
      <v-switch
        v-model='largeChip'
        label='Large chip'
      ></v-switch>
    </div>
    <div class='d-flex flex-column mb-6'>
      <div
        class='d-flex flex-row align-center'
        v-for='(_, i) in status'
        :key='i'
      >
        <dispute-status
          class='ma-2'
          :status='_'
          :outlinedChip="outlinedChip"
          :largeChip="largeChip"
        />
        <span
          v-if='showData'
          class='data ml-10'
        >{{_}}</span>
      </div>

    </div>

  </div>

</template>

<script>
import _ from 'lodash'

export default {
  components: {
    DisputeStatus: () => import('@/components/dispute-collaboration-status')
  },
  created () {
    this.generateData()
  },
  data () {
    return {
      largeChip: false,
      outlinedChip: false,
      showData: false,
      // visualModes: ['textWithChipA', 'textWithChipB', 'visual'],
      selectedVisualMode: 'textWithChipA',
      status: []
    }
  },
  methods: {
    generateData () {
      const d = this.moment().startOf('day')

      this.status = []
      let i
      for (i = -7; i < 7; i++) {
        const text = _.sample(['Status A', 'Status B', 'Status C'])
        const workflow = _.sample(['WF_A', 'WF_B', 'WF_C'])

        this.status.push({
          isBeyondTarget: i < 0,
          targetDate: this.moment(d).add(i, 'day'),
          text: text,
          workflow: {
            name: workflow
          }
        })
      }
    }
  }

}
</script>

<style lang='stylus' scoped>
.data
  color silver
  font-size 0.8em

.select
  max-width 400px
</style>
